<template>
	<div class="container">
		<h2 class="label-header" style="margin-bottom: 15px;">Lecture Note</h2>
		<div class="alert alert-danger alert-dismissable" v-if="showErrorPageAlert">
			{{ errorMessage }}
		</div>
		<div v-if="!isModalOpen">
			<div class="card">
				<div class="card-body" v-if="!isLoading">
					<form @submit.prevent="loadTopic" novalidate>
						<div class="form-group">
							<label class="form-label">Course List</label>
							<select class="form-select" v-model="vv.course_id.$model">
								<option :value="initialValue">Select Course</option>
								<option v-for="(item, i) in courseList" :key="i" :value="item.course_id">{{ item.course_title }}({{ item.course_code }})</option>
							</select>
							<span class="error">{{ vv?.course_id?.$errors[0]?.$message }}</span>
						</div>
						<div class="col-12" style="margin-top: 5px;">
							<button class="btn btn-primary mt-2" style="height: 40px;">
								<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								<span v-if="isProcessing" style="padding-left: 15px !important;">Fetching......</span>
								<span v-if="!isProcessing">Fecth Topics</span>
							</button>
						</div>
					</form>
				</div>
				<div class="card-body loader" v-if="isLoading">
					<div class="spinner-border"></div>
					<h6 style="margin-top: 15px; font-size: 17px;">{{ loadingMessage }}</h6>
				</div>
			</div>
			<div class="card mt-4">
				<div class="card-body">
					<table class="table">
						<thead>
							<tr>
								<th width="20px;"></th>
								<th>Topic Title</th>
								<th width="200px;">Action</th>
							</tr>
						</thead>
						<tbody>
							<template v-if="topicList.length > 0">
								<tr v-for="(item, i) in topicList" :key="i">
									<th width="20px;">{{ i + 1 }}</th>
									<td>{{ item.topic_title }}</td>
									<td width="150px;">
										<button class="btn btn-sm btn-primary" style="margin-right: 10px;" @click.stop="openNote(item)">Open Topic Note</button>
									</td>
								</tr>
							</template>
							<template v-if="topicList.length === 0">
								<tr>
									<td style="text-align: center" colspan="4">No Course Added Yet</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="" v-if="isModalOpen">
			<button @click="closeModal" class="btn btn-danger" style="margin-bottom: 15px;">Close Editor</button>
			<note-editor :courseId="courseId" :topicId="topicId" :topicList="topicList" :courseList="courseList" @closeForm="closeEditorForm"/>
		</div>
	</div>
</template>

<script>
import { reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { projectService } from '@/services/project.service'
import NoteEditor from '@/components/NoteEditor.vue'

export default {
	name: 'LectureNote',
	components: {
		NoteEditor
	},
	setup() {
		const fform = reactive({
			course_id: null
		})
		const srules = {
			course_id: { required }
		}
		const vv = useVuelidate(srules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			role: 0,
			initialValue: null,
			isLoading: true,
			loadingMessage: 'Loading Courses',
			isProcessing: false,
			showErrorPageAlert: false,
			errorMessage: '',
			folder_path: '',
			modal: null,
			courseId: null,
			topicId: null,
			courseList: [],
			topicList: [],
			isModalOpen: false
		};
	},
	mounted () {
		this.folder_path = this.$route.params.folder
		this.loadContent()
	},
	methods: {
		loadContent () {
			projectService.fetchProjectFile(`${this.folder_path}/course.json`)
				.then((response) => {
					this.isLoading = false
					this.courseList = response.data
					//console.log(response.data)
				})
				.catch((error) => {
					console.log(error.message)
				})
		},
		loadTopic () {
			this.showErrorPageAlert = false
			this.isProcessing = true
			projectService.fetchTopicFile(`${this.folder_path}/topic.json`, this.fform.course_id)
				.then((response) => {
					//console.log(this.fform.course_id)
					this.isProcessing = false
					this.courseId = this.fform.course_id
					if(parseInt(response.data.message_code) === 200){
						this.topicList = response.data.topic
					}else{
						this.showErrorPageAlert = true
						this.errorMessage = response.data.message
					}
				})
				.catch((error) => {
					console.log(error)
					this.showErrorPageAlert = true
					this.errorMessage = 'Error Connecting To Server'
				})
		},
		openNote (item) {
			this.topicId = item.topic_id
			this.isModalOpen = true
		},
		closeModal () {
			this.isModalOpen = false
		},
		closeEditorForm () {
			this.isModalOpen = false
		}
	}
};
</script>

<style scoped>
.card .card-body.loader{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
</style>